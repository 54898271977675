// Education.js
import React from 'react';
import './Education.css'; // Assuming you will create a corresponding CSS file

const Education = () => {
  return (
    <div className="education-section">
      <h2>Education</h2>
      <div className="institution">
        <h3>Aeronautics & Computational Simulation MSc - École Nationale Supérieure d'Arts et Métiers, Paris, France</h3>
        <p className="dates">2018 - 2021 </p>
        <ul>
          <li>Integrating computational technologies with traditional aeronautics engineering, focusing on performance and efficiency.</li>
          <li>Specialization in aeronautics engineering, balancing theoretical knowledge with practical application in areas such as design, simulation, and analysis.</li>
          <li>Strong foundation in engineering principles, emphasizing on fluids mechanics and solid mechanics.</li>
        </ul>
        </div>
        <div className="institution">
        <h3>Competitive Classes for French Engineering Schools (eq. Physics BSc) - Lycée Faidherbe, Lille, France</h3>
        <p className="dates">2015 - 2018 </p>
        <ul>
          <li>Completed a preparatory program in PC (Physics-Chemistry) to acquire advanced skills in mathematics, physics, and chemistry, building a strong scientific foundation.</li>
          <li>Developed problem-solving, critical analysis, and time management skills to excel in entrance exams for top engineering, demonstrating determination and academic ambition.</li>
          <li>Engaged in team projects, extracurricular activities, and academic events to enhance communication, leadership, and collaboration skills, while maintaining exceptional academic commitment.</li>
        </ul>
        </div>
    </div>
  );
};

export default Education;
