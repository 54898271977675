import React from 'react';
import Header from './Header'; // Make sure the path matches where your component file is located
import ProfessionalExperience from './ProfessionalExperience';
import Education from './Education';
import TechnicalSkills from './TechnicalSkills';

const CvEnglish = () => {
    return (
    <div className="cv-english">
    <Header />
    <ProfessionalExperience />
    <Education />
    <TechnicalSkills/>
  </div>)}
      
  
  export default CvEnglish;