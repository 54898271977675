// Header.js
import React from 'react';
import './Header.css'; // Assuming you have a CSS file for styling

const Header = () => {
  return (
    <header className="header">
      <h1>Jean Percheron</h1>
      <p>Data Scientist | AI Developer </p>
      <div className="contact-info">
        <p>Email: jean.percheron76@gmail.com</p>
        <p>Phone Number: +33 6 25 06 25 91</p>
        <p>LinkedIn: <a href="https://linkedin.com/in/jeanpercheron" target="_blank" rel="noopener noreferrer">linkedin.com/in/jeanpercheron</a></p>
      </div>
    </header>
  );
};


export default Header;
