import React from 'react';
import './Home.css'; // Assuming a corresponding CSS file for styling

const Home = () => {
    return (
      <div className="home-container">
        <h1>Welcome Dear Visitor </h1>
        <p>
        👋 Hi, I'm Jean Percheron, a Data Scientist with a passion for leveraging technology to solve complex problems.<br /><br />
          Currently innovating at Capgemini Invent, I specialize in developing AI-driven solutions to optimize industrial processes.<br /><br />
          This website serves as a portal to my professional endeavors and achievements. Explore to discover my projects, dive into my technical skills ranging from Web Developement to Machine Learning, and learn about the impact of my work across various sectors.
        </p>
        <img src={`${process.env.PUBLIC_URL}/dzdz.png`} alt="pp" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "500px", height: "auto" }} />
      </div>
    );
  };
  
  
export default Home;
