// ProfessionalExperience.js
import React from 'react';
import './ProfessionalExperience.css'; // Assuming you will create a corresponding CSS file

const ProfessionalExperience = () => {
  return (
    <div className="professional-experience">
      <h2>Professional Experience</h2>
      <div className="job">
        <h3>Data Scientist - Capgemini Invent</h3>
        <p className="dates">2022-2024</p>
        <div className="project">
            <h3> State-of-the-Art Digital Twin Development for Furnace Control Optimization</h3>
                <ul>
                <li>Led the innovation in industrial process modeling by establishing a standardized yet dynamic conceptual framework for Digital Twin development.</li>
                <li>Designed and implemented a cutting-edge methodology for constructing Digital Twins, adeptly blending real-time operational data with sophisticated simulation models.</li>
                <li>Pioneered the development of an AI-driven predictive modeling and inference pipeline, utilizing machine learning algorithms to facilitate real-time analysis and proactive decision-making in furnace operations. This system enabled enhanced predictive maintenance, operational efficiency, and significant energy savings.</li>
                <li>Enhanced the project's scope and impact through a strategic business trip to India, where I engaged directly with clients to understand their specific needs and challenges.</li>
                </ul>
        </div>
      </div>
      <div className="project">
            <h3> Innovative Clinical Trials Acceleration Using AI</h3>
                <ul>
                <li>Spearheaded a transformative project that utilized clinical trials data and physician insights, leveraging AI to revolutionize the field.</li>
                <li>Significantly expedited the clinical trial processes, markedly reducing the time from trial inception to the delivery of medical solutions.</li>
                <li>Conducted comprehensive data analysis to understand trial patterns and outcomes, further refining the AI model for future applications.</li>
                <li>Collaborated with cross-functional teams to ensure the seamless integration of AI technology in clinical settings, emphasizing ethical considerations and patient safety.</li>
                </ul>
        </div>
        <div className="project">
            <h3> Project Framing on a SAS Code Generator Using LLM for a Pharmaceutical Company</h3>
                <ul>
                <li>Spearheaded the framing stage of a key initiative to develop a SAS code generator using Large Language Models (LLMs)</li>
                <li>Excelled in a client-facing role, conducting in-depth consultations to understand and translate complex business requirements into technical solutions.</li>
                <li>Managed cross-functional teams, blending technical expertise with client management to ensure the project met all expectations and delivered tangible business value.</li>
                </ul>
        </div>
        <div className="project">
            <h3> Advanced Topic Modeling of Aircraft Line Maintenance and Breakdowns</h3>
                <ul>
                <li>Orchestrated an extensive project focused on advanced text processing and integrity verification to guarantee precise and reliable analytics in aircraft maintenance.</li>
                <li>Conducted a thorough analysis of maintenance reports and operational data, using advanced topic modeling techniques to uncover underlying trends.</li>
                <li>Collaborated with maintenance teams and engineers to validate findings and integrate insights into operational procedures, significantly enhancing maintenance efficiency and aircraft reliability.</li>
                </ul>
        </div>
        <div className="job">
        <h3>Process Data Engineer Intern - Lisi Aerospace</h3>
        <p className="dates">2021-2022</p>
        <div className="project">
            <h3> Sophisticated Data Engineering for Enhanced Quality Data Management</h3>
                <ul>
                <li>Initiated the deployment of a comprehensive quality control dashboard, offering real-time insights into production status and quality metrics.</li>
                <li>Led the intricate process of data extraction transforming and standardizing data for integration into an SQL database, ensuring consistency and reliability.</li>
                <li>Implemented innovative control cards for operators, utilizing predictive analytics to forecast machine maintenance needs, thereby enhancing operational efficiency and reducing downtime.</li>
                </ul>
        </div>
      </div>
        </div>
        
  );
};

export default ProfessionalExperience;
