import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import NavigationBar from './components/NavigationBar'; // Assurez-vous d'avoir ce composant
import Home from './components/Home'; // Votre composant Home
import CvEnglish from './components/CvEnglish'; // Votre composant pour le CV en anglais
// Importez d'autres composants de page selon vos besoins

import './App.css'; // Votre fichier CSS global

const App = () => {
  return (
    <Router>
      <NavigationBar />
      <Content />
    </Router>
  );
};

// Composant pour gérer le contenu et les transitions
const Content = () => {
  let location = useLocation(); // Hook pour accéder à l'objet location courant

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        classNames="fade" // Assurez-vous que les noms correspondent à votre fichier CSS pour les transitions
        timeout={300} // Durée de la transition en millisecondes
      >
        <div className="content"> {/* Wrapper pour appliquer la transition */}
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/cv-english" element={<CvEnglish />} />
            {/* Définissez d'autres routes ici */}
          </Routes>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default App;
