// NavigationBar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavigationBar.css'; // Assuming you will create a corresponding CSS file

const NavigationBar = () => {
  return (
    <nav>
      <ul>
        <li><NavLink to="/" end>🏠 Home</NavLink></li>
        <li><NavLink to="/about-me">🤖 About Me </NavLink></li>
        <li><NavLink to="/cv-english">🇬🇧 Curriculum Vitae</NavLink></li>
        <li><NavLink to="/cv-french">🇫🇷 Curriculum Vitae</NavLink></li>
      </ul>
    </nav>
  );
};

export default NavigationBar;