import React from "react";
import './TechnicalSkills.css'

const TechnicalSkills = () => {
    return(
    <div className="technical-skills">
    <h2>Technical Skills</h2>
    <div className="technical-skills-container">
   <div className="skill"> <strong>Programming Languages </strong>: Python (Proefficient), Javascript (Intermediate) </div>
   <div className="skill"> <strong>Machine Learning </strong>: Scikit Learn, PyTorch, Tensor Flow, Keras, PyTorch </div>
   <div className="skill"> <strong>Data Manipulation </strong>: Pandas, Numpy, Apache Spark </div>
   <div className="skill"> <strong>Visualisation </strong>: Matplotlib, Flask, Streamlit, Power Bi, Plotly </div>
   <div className="skill"> <strong>Cloud Provider </strong>: Azure (Experimented), GCP (Intermediate)  </div>
   <div className="skill"> <strong>Deployment </strong>: Docker, Kubernetes, Helm  </div>
   <div className="skill"> <strong>Database</strong>: PostgreSQL, Neo4j, MySQL</div>
   <div className="skill"> <strong>Certification</strong>: AI-104 (in progress)</div>
   </div>
    </div> 
    )
}
export default TechnicalSkills;
